.message-quill-editor {
  min-height: 120px;
}
.message-quill-editor .ql-editor {
  @apply font-sans;
}

.message-quill-editor .ql-container.ql-snow {
  @apply border-none;
}

.send-message .ql-toolbar.ql-snow {
  @apply border-0;
  @apply border-t;
  @apply border-gray-300;
}

.message-quill-editor .ql-editor h1, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  @apply text-3xl;
}

.message-quill-editor .ql-editor h2, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  @apply text-2xl;
}

.message-quill-editor .ql-editor h3, .message-quill-editor .ql-editor h4, .message-quill-editor .ql-editor h5, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  @apply text-xl;
}