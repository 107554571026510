.custom-quill-editor .ql-editor {
  @apply font-sans;
  @apply prose;
  @apply min-h-48;
}

.custom-quill-editor .ql-editor h1, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  @apply text-3xl;
  @apply font-bold;
}

.custom-quill-editor .ql-editor h2, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  @apply text-2xl;
  @apply font-semibold;
}

.custom-quill-editor .ql-editor h3, .custom-quill-editor .ql-editor h4, .custom-quill-editor .ql-editor h5, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  @apply text-2xl;
  @apply font-light;
}

.custom-quill-editor .ql-editor h6, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  @apply text-lg;
  @apply font-normal;
}

.custom-quill-editor .ql-editor hr {
  @apply rounded-sm;
  @apply border-indigo-600;
  width: 40%;
  @apply border-t-4;
  @apply mt-12;
  @apply mb-6;
}

.custom-quill-editor .ql-editor ul > li {
  @apply pl-0;
}