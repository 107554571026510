@tailwind base;
@tailwind components;
@tailwind utilities;


.wrap-links a[href] {
  word-break: break-all
}

img[src*="http://email.outbound-mail.greenhouse.io/o"] {
  display: none;
}

.ql-align-center {
  @apply text-center;
}

.ql-align-right {
  @apply text-right;
}

.ql-align-justify {
  @apply text-justify;
}


.experience-stage-details h1 {
  @apply text-3xl;
  @apply font-bold;
}

.experience-stage-details h2 {
  @apply text-2xl;
  @apply font-semibold;
}

.experience-stage-details h3, .experience-stage-details h4, .experience-stage-details h5 {
  @apply text-2xl;
  @apply font-light;
  @apply inline-block;
}

.experience-stage-details h6 {
  @apply text-lg;
}

.experience-stage-details hr {
  @apply rounded-sm;
  @apply border-indigo-600;
  width: 40%;
  @apply border-t-4;
  @apply mt-12;
  @apply mb-6;
}
